import { hasWindow } from '../utils';
import { pageNameToType, buttonCategoryMap, vehicleToCommerce, vehicleToCommerceClick, companyCodeToParams } from './analyticsGDDLmapping';
import analyticstracker from 'analyticstracker';
import analyticsTransQA from 'analytics-transQA';
import analyticsTransGTM from 'analytics-transGTM';

import TagManager from 'react-gtm-module';

const eventTypes = {
  pageImpression: 'page-impression',
  buttonClick: 'button-click',
  toolStart: 'tool-start',
  toolSubmit: 'tool-submit',
  toolComplete: 'tool-complete',
  toolError: 'tool-error',
  productDetail: 'product-detail',
  productClick: 'product-click',
  searchQuery: 'search-query',
  searchResultclick: 'search-resultclick',
  contentblockImpression: 'content-block-impression',
  notificationImpression: 'notification-impression',
  notificationClick: 'notification-click'
};

const EMPTY_COMMERCE = {
  carBrand: '',
  carModel: '',
  referenceID: '',
  carConfigCode: '',
  carEquipmentLine: '',
  carEngineType: '',
  carEnginePower: '',
  carGearboxType: '',
  carExteriorColor: '',
  carInteriorColor: '',
  carOptionsList: '',
  carPrice: '',
  carTotalPrice: '',
  carYear: '',
  carMileage: ''
};

export const EMPTY_CARFILTER = {
  totalPrice: '',
  monthlyPrice: '',
  makeModels: '',
  category: '',
  fuel: '',
  engine: '',
  emission: '',
  firstRegistrationYear: '',
  mileage: '',
  kw: '',
  pk: '',
  transmission: '',
  doors: '',
  seats: '',
  equipment: '',
  vin: '',
  reference: '',
  customerType: '',
  euroNorm: ''
};

const analyticsInitialize = (settings) => {
  const tagManagerArgs = {
    gtmId: settings.id
  };
  TagManager.initialize(tagManagerArgs);
};

const analyticsEventSubmit = (tracker, event) => {
  if (hasWindow() && tracker) {
    try {
      let aTrack = tracker(); // this is an instance of analyticsTracker sent on initialization by the user of core
      aTrack.trackEvent(event);
    } catch (err) {
      console.log('Event did not track correctly, reason:', err);
    }
  }
};

const pageImpressionEventInitialize = (page, data, context, session) => { // see if it is possible to see context value (pro or private)
  const generateEventObject = (page, data, context, session) => {
    const fullUrl = window.location.href;
    const searchParams = new URLSearchParams(window.location.search);

    // const pageNameParts = [data.platform, data.section, data.subsectionOne, data.subsectionTwo];

    // var filteredPageNameParts = pageNameParts.filter((obj) => obj);
    var section, subsectionOne, subsectionTwo;

    if (page?.name) {
      const pagenameParts = page.name.split('/');
      section = pagenameParts[1];
      subsectionOne = pagenameParts[2];
      subsectionTwo = pagenameParts[3];
    }

    var loggedIn = session?.authenticated ? 'logged in' : 'not logged in';
    let history = window.history.state;

    let prevKey, prevLang, prevName, prevType, prevUrl;

    if (history?.previousPageInfo) {
      prevKey = history?.previousPageInfo.key;
      prevLang = history?.previousPageInfo.language;
      prevName = history?.previousPageInfo.name;
      prevType = history?.previousPageInfo.type;
      prevUrl = history?.previousPageInfo.url;
    }

    const prevFullUrl = prevUrl ? window.location.origin + prevUrl : undefined;

    const companyKey = context.companyCode === 'myway' ? 'myway' : 'default';

    const { brand, platform, pageviewMode } = companyCodeToParams[companyKey];
    const previousPageUrl = prevFullUrl ? prevFullUrl.split('?')[0] : undefined;
    const pageType = pageNameToType[companyKey][page.name];

    let newPageName = page?.name?.startsWith('myway/')? page?.name?.replace('myway/', 'main/') : page?.name || '';

    const returnValue = {
      event: eventTypes.pageImpression,
      info: {
        // pageName: page?.name || '',
        pageName: newPageName,
        section: section || '',
        subsectionOne: subsectionOne || '',
        subsectionTwo: subsectionTwo || '',
        pageUrl: fullUrl.split('?')[0],
        pageFullUrl: fullUrl || '',
        pageType: pageType || '',
        platform: platform,
        brand: brand,
        language: context?.culture || '',
        loginStatus: loggedIn || '',
        previousPageName: prevName || '',
        previousPageUrl: previousPageUrl || '',
        previousPageFullUrl: prevFullUrl || '',
        previousPageType: pageNameToType[companyKey][prevName] || '',
        previousPlatform: platform,
        previousBrand: brand,
        pageVersion: '',
        pageviewMode: companyKey !== 'myway' ? pageviewMode : context?.page?.includes('dealerGroup') ? 'dealer' : pageviewMode, // if companyKey is myway, then add professional or private
        author: 'autralis',
        dealerGroupID: context?.dealerGroup?.externalReference || data?.dealer?.group?.externalReference || '',
        dealerGroupName: context?.dealerGroup?.name || data?.dealer?.group?.name || '',
        dealerId: data?.dealer?.externalReference || '',
        dealerName: data?.dealer?.name || '',
        dealerLocation: data?.dealer ? `${data?.dealer?.address}, ${data?.dealer?.postal} ${data?.dealer?.city}` : '',
        expertReference: data?.reference || '',
        labels: data?.labels.join('|') || '',
        initialReferrer: '' // the page where you originally came from before landing on the myway/lma website
      }
    };

    // // read data
    // console.log('[ !! DEBUG !! ] returnValues => ', returnValue || 'no returnValue');
    // console.log('[ !! DEBUG !! ] data => ', data || 'no data');
    // console.log('[ !! DEBUG !! ] context => ', context || 'no context');
    //
    // // test changes in console first.
    // console.log('[ !! DEBUG !! ] OLD pageName =>', returnValue.info.pageName);
    // console.log('[ !! DEBUG !! ] NEW pageNameShort =>', newPageName);

    //
    return returnValue;
  };

  try {
    return generateEventObject(page, data, context, session);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const buttonCtaEventInitialize = (props, context) => {
  const generateEventObject = (props, context) => {
    let buttonTarget;
    if (props.buttonTarget === 'inbound-link' || props.buttonTarget === 'internal-link') {
      if (props.buttonTarget || props.page) {
        buttonTarget = window.location.origin + props.buttonTarget || props.page;
      } else {
        buttonTarget = props.url;
      }
    } else {
      buttonTarget = props.url || props.buttonActionUrl || props.page;
    }

    return {
      event: eventTypes.buttonClick,
      info: {
        buttonType: props.buttonType || props.analyticsButtonType || 'button',
        buttonCategory: props.buttonCategory ? buttonCategoryMap[props.buttonCategory] : props.analyticsButtonCategory || 'undefined-link',
        buttonName: props.name || props.analyticsButtonName || props.buttonName || '',
        buttonText: props.buttonText || props.text || props.analyticsButtonText || '',
        buttonTarget: buttonTarget || '',
        buttonPosition: props.position || ''
      }
    };
  };

  try {
    return generateEventObject(props, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const productDetailInitialize = (props, context) => {
  const generateEventObject = (props, context) => {
    const commerce = vehicleToCommerce(props.vehicle);

    return {
      event: eventTypes.productDetail,
      commerce: commerce
    };
  };

  try {
    return generateEventObject(props, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const productClickInitialize = (props, context) => {
  const generateEventObject = (props, context) => {
    const commerce = vehicleToCommerceClick(props.vehicle);

    return {
      event: eventTypes.productClick,
      commerce: commerce
    };
  };

  try {
    return generateEventObject(props, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const toolStartEventInitialize = (type, data, context) => {
  const generateEventObject = (type, data, context) => {
    const commerce = data.vehicle ? vehicleToCommerce(data.vehicle) : EMPTY_COMMERCE;

    return {
      event: eventTypes.toolStart,
      info: {
        toolType: data.toolType || '',
        toolName: data.toolName || '',
        toolStep: data.toolStepStart || '',
        toolStepOption: '',
        toolStepNumber: data.toolStepNumber || '1',
        requestType: data.requestType || '',
        leadID: '',
        errorMessage: '',
        errorType: ''
      },
      commerce: commerce
    };
  };

  try {
    return generateEventObject(type, data, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const toolSubmitEventInitialize = (type, data, context) => {
  const generateEventObject = (type, data, context) => {
    const additionalData = data?.additionalData ? data.additionalData : data; //sometimes it's in additionalData sometimes in data...
    const commerce = additionalData?.vehicle ? vehicleToCommerce(additionalData?.vehicle) : EMPTY_COMMERCE;

    return {
      event: eventTypes.toolSubmit,
      info: {
        toolType: additionalData?.toolType || '',
        toolName: additionalData?.toolName || '',
        toolStep: additionalData?.toolStepStart || '',
        toolStepOption: additionalData?.toolStepOption || '',
        toolStepNumber: additionalData.toolStepNumber || '1',
        requestType: additionalData?.requestType || '',
        leadID: '',
        errorMessage: '',
        errorType: ''
      },
      commerce: commerce
    };
  };

  try {
    return generateEventObject(type, data, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const toolCompleteEventInitialize = (props) => {
  const generateEventObject = (props) => {
    const commerce = props.vehicle ? vehicleToCommerce(props.vehicle) : EMPTY_COMMERCE;

    return {
      event: eventTypes.toolComplete,
      info: {
        toolType: props.toolType || '',
        toolName: props.toolName || '',
        toolStep: props.toolStep || '',
        toolStepOption: props.toolStepOption || '',
        toolStepNumber: props.toolStepNumber || '2',
        requestType: props.requestType || '',
        leadID: '',
        errorMessage: '',
        errorType: ''
      },
      commerce: commerce
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const toolErrorEventInitialize = (type, data, context) => {
  const generateEventObject = (type, data, context) => {
    const additionalData = data;
    const commerce = additionalData?.vehicle ? vehicleToCommerce(additionalData?.vehicle) : EMPTY_COMMERCE;
    const errorMessageString = data.errors ? Object.values(data.errors).join(', ') : '';
    return {
      event: eventTypes.toolError,
      info: {
        toolType: additionalData?.toolType || '',
        toolName: additionalData?.toolName || '',
        toolStep: additionalData?.toolStepError || '',
        toolStepOption: additionalData?.toolStepOption || '',
        toolStepNumber: additionalData?.toolStepNumber || '1',
        requestType: additionalData?.requestType || '',
        leadID: '',
        errorMessage: errorMessageString ? `The following errors were thrown: ${errorMessageString}` : '',
        errorType: type || ''
      },
      commerce: commerce
    };
  };

  try {
    return generateEventObject(type, data, context);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const tranformFilters = (filters) => {
  const intermediateFilter = filters.reduce(function (r, filter) {
    r[filter.type] = r[filter.type] || [];
    let value;
    if (Array.isArray(filter.value)) {
      value = filter.value.join('-');
    } else {
      if (filter.type === 'makeModel') {
        value = filter.value.replace('|', '-');
      } else {
        value = filter.value;
      }
    }

    r[filter.type].push(value);
    return r;
  }, Object.create(null));

  let formattedFilters = {};
  for (const [key, value] of Object.entries(intermediateFilter)) {
    formattedFilters[key] = value.join('|');
  }

  const filtersWithCorrectKeys = EMPTY_CARFILTER;
  filtersWithCorrectKeys['totalPrice'] = formattedFilters['price'] || '';
  filtersWithCorrectKeys['monthlyPrice'] = formattedFilters['monthlyPrice'] || '';
  filtersWithCorrectKeys['makeModels'] = formattedFilters['makeModel'] || '';
  filtersWithCorrectKeys['category'] = formattedFilters['body'] || '';
  filtersWithCorrectKeys['engine'] = formattedFilters['fuel'] || '';
  filtersWithCorrectKeys['firstRegistrationYear'] = formattedFilters['firstRegistrationYear'] || '';
  filtersWithCorrectKeys['mileage'] = formattedFilters['mileage'] || '';
  filtersWithCorrectKeys['pk'] = formattedFilters['pk'] || '';
  filtersWithCorrectKeys['kw'] = formattedFilters['kw'] || '';
  filtersWithCorrectKeys['transmission'] = formattedFilters['transmission'] || '';
  filtersWithCorrectKeys['doors'] = formattedFilters['doorsRange'] || '';
  filtersWithCorrectKeys['seats'] = formattedFilters['seatsRange'] || '';
  filtersWithCorrectKeys['equipment'] = formattedFilters['equipments'] || '';
  filtersWithCorrectKeys['euroNorm'] = formattedFilters['euroNorm'] || '';
  filtersWithCorrectKeys['emission'] = formattedFilters['co2Range'] || '';
  filtersWithCorrectKeys['reference'] = formattedFilters['identification'] || '';
  filtersWithCorrectKeys['customerType'] = formattedFilters['customerType'] || '';

  return filtersWithCorrectKeys;
};

const searchQueryEventInitialize = (props) => {
  const generateEventObject = (props) => {
    const filtersWithCorrectKeys = tranformFilters(props.filters);
    return {
      event: eventTypes.searchQuery,
      info: {
        searchType: 'filter',
        searchKeyword: props.searchKeyword || '',
        searchResult: props.searchResult || '',
        searchResultPosition: props.searchResultPosition || '',
        searchResultCount: props.searchResultCount?.toString() || '',
        filter: filtersWithCorrectKeys || EMPTY_CARFILTER
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const filterQueryEventInitialize = (props) => {
  const generateEventObject = (props) => {
    const filtersWithCorrectKeys = tranformFilters(props.filters);

    return {
      event: eventTypes.searchQuery,
      info: {
        searchType: 'filter',
        searchKeyword: props.searchKeyword || '',
        searchResult: props.searchResult || '',
        searchResultPosition: props.searchResultPosition || '',
        searchResultCount: props.count?.toString() || '',
        filter: filtersWithCorrectKeys || EMPTY_CARFILTER
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const searchResultclickEventInitialize = (props) => {
  const generateEventObject = (props) => {
    const filtersWithCorrectKeys = tranformFilters(props.searchResultFilter);

    return {
      event: eventTypes.searchResultclick,
      info: {
        searchType: 'filter',
        searchKeyword: props.searchKeyword || '',
        searchResult: props.searchResult || '',
        searchResultPosition: props.searchResultPosition?.toString() || '',
        searchResultCount: props.searchResultCount?.toString() || '',
        filter: filtersWithCorrectKeys || EMPTY_CARFILTER
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const contentblockImpressionEventInitialize = (containerName) => {
  const generateEventObject = (containerName) => {
    return {
      event: eventTypes.contentblockImpression,
      info: {
        containerName: containerName || '',
        containerPosition: '',
        containerVersion: ''
      }
    };
  };

  try {
    return generateEventObject(containerName);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const notificationImpressionEventInitialize = (props) => {
  const generateEventObject = (props) => {
    return {
      event: eventTypes.notificationImpression,
      info: {
        notificationType: props.notificationType || 'banner',
        notificationCategory: props.notificationCategory || '',
        notificationName: props.notificationName || '',
        notificationOption: props.notificationOption || ''
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

const notificationClickEventInitialize = (props) => {
  const generateEventObject = (props) => {
    return {
      event: eventTypes.notificationClick,
      info: {
        notificationType: props.notificationType || 'banner',
        notificationCategory: props.notificationCategory || '',
        notificationName: props.notificationName || '',
        notificationOption: props.notificationOption || 'close'
      }
    };
  };

  try {
    return generateEventObject(props);
  } catch (error) {
    // in case something fails we don't want the website to halt but to display an error
    console.error(error);
    return {};
  }
};

export const initialize = (GDDL_ID) => {
  const tracker = hasWindow() ? analyticstracker : undefined;

  return {
    name: 'GDDL',
    settings: {
      id: GDDL_ID
    },
    tracker: tracker,

    analyticsInitialize: (settings) => analyticsInitialize(settings),
    pageImpressionEventInitialize: (page, data, context, session) => pageImpressionEventInitialize(page, data, context, session),
    analyticsEventSubmit: (event) => analyticsEventSubmit(tracker, event),
    productDetailInitialize: (props, context) => productDetailInitialize(props, context),
    productClickInitialize: (props, context) => productClickInitialize(props, context),
    buttonCtaEventInitialize: (props, context) => buttonCtaEventInitialize(props, context),
    toolStartEventInitialize: (type, data, context) => toolStartEventInitialize(type, data, context),
    toolSubmitEventInitialize: (type, data, context) => toolSubmitEventInitialize(type, data, context),
    toolCompleteEventInitialize: (props) => toolCompleteEventInitialize(props),
    toolErrorEventInitialize: (type, data, context) => toolErrorEventInitialize(type, data, context),
    searchQueryEventInitialize: (props) => searchQueryEventInitialize(props),
    filterQueryEventInitialize: (props) => filterQueryEventInitialize(props),
    searchResultclickEventInitialize: (props) => searchResultclickEventInitialize(props),
    contentblockImpressionEventInitialize: (context) => contentblockImpressionEventInitialize(context),
    notificationImpressionEventInitialize: (props) => notificationImpressionEventInitialize(props),
    notificationClickEventInitialize: (props) => notificationClickEventInitialize(props)
  };
};
