import React, {useRef, useEffect} from 'react';
import {getKeyValueContentFieldsForBlock, hasWindow} from '../utils';
import * as components from 'dealer-website-components';
import {useAnalytics} from 'dealer-website-components';
import settings from '../../config';
import {useIntersection} from './utils';

const Block = (props) => {
    const {block, context, store, overridePageMetaData, resolveAnalyticsData, pageVariables} = props
    const {initializeTracker, analyticsTracker} = useAnalytics(context);
    const ref = useRef();
    const inViewport = useIntersection(ref)

    useEffect(() => {
        if (inViewport && hasWindow() && analyticsTracker !== undefined && block.name) {
            const contentBlockEvent = analyticsTracker.contentblockImpressionEventInitialize(block.name);
            analyticsTracker.analyticsEventSubmit(contentBlockEvent);
        }
    }, [analyticsTracker, inViewport]);

    const Component = components[block.variant];
    if (!Component) {
        return null;
    }

    return (
        <div id={`b${block.id.split('-')[0]}`} ref={ref}>
            <Component
                {...getKeyValueContentFieldsForBlock(block, context.culture)}
                {...context.properties}
                {...pageVariables} // f.e 'reference on vehicle detail page
                context={context}
                store={store}
                overridePageMetaData={overridePageMetaData}
                resolveAnalyticsData={resolveAnalyticsData}
                location={props.location}
            />
        </div>
    );
};
export default Block;
